<template>
  <v-form
    ref="form"
    v-model="valid"
  >
    <v-card
      elevation="24"
    >
      <v-card-title v-if="isCitizen">
        اضافة سكنة منطقة
      </v-card-title>
      <v-card-title v-if="!isCitizen">
        اضافة صاحب مركبة
      </v-card-title>
      <v-container>
        <v-row class="mt-2">
          <v-col
            cols="6"
            md="6"
            sm="12"
          >
            <v-text-field
              v-model="form.name"
              v-max-length="200"
              :rules="rules.nameRules"
              label="اسم الشخص"
              outlined
              required
            ></v-text-field>
          </v-col>
          <v-col
            cols="6"
            md="6"
            sm="12"
          >
            <v-text-field
              v-model="form.phone_number"
              v-max-length="200"
              :rules="rules.phone_numberRules"
              label="رقم الهاتف"
              type="text"
              outlined
              required
            ></v-text-field>
          </v-col>

          <v-col
            cols="6"
            md="6"
            sm="12"
          >
            <v-text-field
              v-model="form.address"
              v-max-length="200"
              label="العنوان"
              outlined
              required
            ></v-text-field>
          </v-col>
          <v-col
            v-if="isCitizen"
            cols="6"
            md="6"
            sm="12"
          >
            <v-text-field
              v-model="form.identification_number"
              v-max-length="200"
              :rules="rules.identification_number"
              label="الرقم التعريفي"
              type="number"
              outlined
              required
            ></v-text-field>
          </v-col>
          <v-col
            cols="3"
            md="3"
            sm="6"
          >
            <v-text-field
              v-model="form.sequence"
              v-max-length="200"
              :rules="rules.sequenceRules"
              label="التسلسل"
              type="number"
              outlined
              required
            ></v-text-field>
          </v-col>
          <v-col
            cols="3"
            md="3"
            sm="6"
          >
            <v-text-field
              v-model="form.page"
              v-max-length="200"
              :rules="rules.pageRules"
              label="الصفحة"
              type="number"
              outlined
              required
            ></v-text-field>
          </v-col>

          <v-col
            v-if="!isCitizen"
            cols="6"
            md="6"
            sm="12"
          >
            <v-textarea
              v-model="form.dignitary_note"
              label="ملاحظات شخصية"
              outlined
              rows="2"
              hint="ملاحظات شخصية"
            ></v-textarea>
          </v-col>
          <v-col
            cols="6"
            md="6"
            sm="12"
          >
            <v-select
              v-model="form.active"
              :items="CheckActive"
              :rules="rules.activeRules"
              item-text="state"
              item-value="value"
              label="الحالة"
              outlined
            ></v-select>
          </v-col>
          <v-col
            v-if="!isCitizen"
            cols="6"
            md="6"
            sm="12"
          >
            <v-select
              v-model="form.enter_all_cars"
              :items="Enter_All_Cars"
              item-text="state"
              item-value="value"
              label="دخول السيارات برفقته"
              outlined
            ></v-select>
          </v-col>
          <v-col
            v-if="form.enter_all_cars === 1 && !isCitizen"
            cols="6"
            md="6"
            sm="12"
          >
            <v-text-field
              v-model="form.cars_count"
              v-max-length="200"
              :rules="rules.cars_count"
              type="number"
              label="عدد العجلات المسموح بدخولها"
              outlined
              hint="عدد العجلات المسموح بدخولها"
            ></v-text-field>
          </v-col>
          <v-col
            cols="6"
            md="6"
            sm="12"
          >
            <v-select
              v-model="form.level"
              :items="Levels"
              :rules="rules.levelRules"
              item-text="state"
              item-value="value"
              label="السماح بالدخول في الزيارات"
              hide-details
              outlined
            ></v-select>
          </v-col>
          <v-col
            cols="6"
            md="6"
            sm="12"
          >
            <v-autocomplete
              v-model="form.identifier_id"
              :items="identifiers"
              item-text="name"
              item-value="id"
              clearable
              label="المعرف"
              outlined
            ></v-autocomplete>
          </v-col>
          <v-col
            cols="6"
            md="6"
            sm="12"
          >
            <v-textarea
              v-model="form.identifier_type"
              label="طريقة التعريف"
              outlined
              rows="2"
              hint="طريقة التعريف"
            ></v-textarea>
          </v-col>

          <v-col
            cols="6"
            md="6"
            sm="12"
          >
            <v-autocomplete
              v-model="form.checkpoints"
              :items="checkpoints"
              item-text="name"
              multiple
              item-value="id"
              label="السيطرات"
              outlined
            ></v-autocomplete>
          </v-col>

          <v-col
            cols="6"
            md="6"
            sm="12"
          >
            <date-picker
              v-model="form.expire_date"
              style="width: 100%;"
              class="mb-5"
              placeholder="تاريخ الانتهاء"
              value-type="format"
            ></date-picker>
          </v-col>
          <v-col
            :cols="imagePreview ? 9 : 12"
          >
            <v-file-input
              ref="file"
              v-model="form.image"
              type="file"
              :hidden="true"
              accept="image/png, image/jpeg, image/bmp"
              placeholder="الرجاء اختيار صورة"
              prepend-icon="mdi-camera"
              label="صورة الشخص"
              @change="fileChanged"
            ></v-file-input>
          </v-col>
          <v-col
            v-if="imagePreview"
            cols="3"
          >
            <v-avatar
              color=""
              class="v-avatar-light-bg primary--text mb-1 "
              size="100"
              rounded
            >
              <v-img
                :src="imagePreview"
              >
              </v-img>
            </v-avatar>
          </v-col>
        </v-row>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :loading="loading"
            :disabled="loading"
            class="mr-4"
            color="success"
            @click="submit"
          >
            {{ $t('form.create') }}
          </v-btn>
          <v-btn
            color="info"
            :to="{name: 'peoples.index'}"
          >
            {{ $t('form.back') }}
          </v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-form>
</template>
<script>
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/ar-sa'
import DatePicker from 'vue2-datepicker'
import axios from '@axios'
import toast from '@/store/toast'

export default {
  components: {DatePicker},

  async fetch() {
    const response = await axios.get('/identifiers');
    this.identifiers = response.data.data
  },
  data: () => ({
    loading: false,
    identifiers: [],
    checkpoints: [],
    valid: true,
    imagePreview: null,
    form: {
      name: '',
      phone_number: '',
      sequence: '',
      page: '',
      dignitary_note: '',
      active: 1,
      address: '',
      cars_count: '',
      enter_all_cars: '',
      level: 0,
      image: null,
      expire_date: '',
      identifier_id: '',
      identification_number: '',
      identifier_type: '',
      checkpoints: [],
    },
    CheckActive: [
      {state: 'فعال', value: 1},
      {state: 'غير فعال', value: 0},
    ],
    Enter_All_Cars: [
      {state: 'يسمح', value: 1},
      {state: 'لايسمح', value: 0},
    ],
    Levels: [
      {state: 'كلا', value: 0, color: 'success'},
      {state: 'مستوى اول', value: 1, color: 'primary'},
      {state: 'مستوى ثاني', value: 2, color: 'warning'},
      {state: 'مستوى ثالث', value: 3, color: 'error'},
    ],

  }),
  computed: {
    isCitizen() {
      return this.$route.meta.type === 'citizen';
    },
    rules() {
      const rules = {};
      rules.nameRules = [
        v => !!v || this.$t('form.required', ['الاسم']),
      ];
      rules.phone_numberRules = [
        v => !v || /\d{11}/.test(v) || this.$t('form.pattern', ['رقم الهاتف']),
      ];
      rules.sequenceRules = [
        v => !v || v > 0 || this.$t('form.negative', ['التسلسل']),
      ];
      rules.pageRules = [
        v => !v || v > 0 || this.$t('form.negative', ['الصفحة']),
      ];
      rules.dignitary_noteRules = [
        v => !!v || this.$t('form.required', ['الملاحظة الشخصية']),
      ];
      rules.activeRules = [
        v => v !== null || this.$t('form.required', ['الحالة']),
        v => v !== '' || this.$t('form.required', ['الحالة']),
      ];
      rules.enter_all_carsRules = [
        v => v !== null || this.$t('form.required', ['دخول السيارات برفقته']),
        v => v !== '' || this.$t('form.required', ['دخول السيارات برفقته']),
      ];
      rules.levelRules = [
        v => v !== null || this.$t('form.required', ['المستوى']),
        v => v !== '' || this.$t('form.required', ['المستوى']),
      ];
      rules.identifier_idRules = [
        v => v !== null || this.$t('form.required', ['المعرف']),
        v => v !== '' || this.$t('form.required', ['المعرف']),
      ];
      rules.checkpoints = !this.isCitizen ? [] : [
        v => v !== null || this.$t('form.required', ['السيطرات']),
        v => v !== '' || this.$t('form.required', ['السيطرات']),
      ];
      rules.cars_count = [
        v => v !== null || this.$t('form.required', ['عدد العجلات المسموح بدخولها']),
        v => v !== '' || this.$t('form.required', ['عدد العجلات المسموح بدخولها']),
        v => v > 0 || this.$t('form.negative', ['عدد العجلات المسموح بدخولها']),
      ];
      rules.identifier_type = [
        v => v !== null || this.$t('form.required', ['طريقة التعريف']),
        v => v !== '' || this.$t('form.required', ['طريقة التعريف']),
      ];
      rules.identification_number = [
        v => v !== null || this.$t('form.required', ['الرقم التعريفي']),
        v => v !== '' || this.$t('form.required', ['الرقم التعريفي']),
      ];
      rules.image = [
        v => !!v || this.$t('form.required', ['الصورة']),
      ];

      return rules;
    },
  },
  async mounted() {
    const response = await axios.get('/identifiers');
    this.identifiers = response.data.data
    const check = await axios.get('/checkpoints')
    this.checkpoints = check.data.data
  },
  methods: {
    async submit() {
      this.loading = true;
      try {
        if (this.$refs.form.validate()) {
          const formData = new FormData();
          formData.append('image', this.form.image);
          formData.append('name', this.form.name);
          formData.append('phone_number', this.form.phone_number);
          formData.append('address', this.form.address);
          formData.append('sequence', this.form.sequence);
          formData.append('page', this.form.page);
          formData.append('expire_date', this.form.expire_date);
          formData.append('active', this.form.active);
          formData.append('identifier_type', this.form.identifier_type);
          if (!this.isCitizen) {
            if (this.form.enter_all_cars) {
              formData.append('enter_all_cars', this.form.enter_all_cars);
            }
            formData.append('cars_count', this.form.cars_count);
            formData.append('dignitary_note', this.form.dignitary_note);
          } else {
            formData.append('identification_number', this.form.identification_number);
          }
          formData.append('citizen', this.isCitizen ? '1' : '0');
          formData.append('level', this.form.level);
          if (this.form.identifier_id) {
            formData.append('identifier_id', this.form.identifier_id);
          }
          this.form.checkpoints.forEach(id => {
            formData.append('checkpoints[]', id);
          })
          await axios.post('/peoples/create', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
          await toast.dispatch('success', this.$t('form.success.create'))
          this.$router.push({name: this.isCitizen ? 'citizens.index' : 'peoples.index'})
        }
      } finally {
        this.loading = false;
      }
    },

    fileChanged() {
      if (!this.form.image) {
        this.imagePreview = null

        return null
      }
      const reader = new FileReader()
      reader.readAsDataURL(this.form.image)
      reader.onload = () => {
        this.imagePreview = reader.result
      }
      reader.onerror = error => {
        console.log('Error: ', error)
      }

      return null
    },
    resetForm() {
      if (this.$refs.form) {
        this.$refs.form.reset();
      }
      this.imagePreview = null;
    },
  },

}
</script>
